// Copyright 2021-2024 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { boot } from "../../../../codegenv1/boot.js";
import { messageDesc } from "../../../../codegenv1/message.js";
import { enumDesc } from "../../../../codegenv1/enum.js";
/**
 * Describes the file google/protobuf/descriptor.proto.
 */
export const file_google_protobuf_descriptor = /*@__PURE__*/boot({
  "name": "google/protobuf/descriptor.proto",
  "package": "google.protobuf",
  "messageType": [{
    "name": "FileDescriptorSet",
    "field": [{
      "name": "file",
      "number": 1,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FileDescriptorProto"
    }]
  }, {
    "name": "FileDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "package",
      "number": 2,
      "type": 9,
      "label": 1
    }, {
      "name": "dependency",
      "number": 3,
      "type": 9,
      "label": 3
    }, {
      "name": "public_dependency",
      "number": 10,
      "type": 5,
      "label": 3
    }, {
      "name": "weak_dependency",
      "number": 11,
      "type": 5,
      "label": 3
    }, {
      "name": "message_type",
      "number": 4,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.DescriptorProto"
    }, {
      "name": "enum_type",
      "number": 5,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.EnumDescriptorProto"
    }, {
      "name": "service",
      "number": 6,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.ServiceDescriptorProto"
    }, {
      "name": "extension",
      "number": 7,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FieldDescriptorProto"
    }, {
      "name": "options",
      "number": 8,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FileOptions"
    }, {
      "name": "source_code_info",
      "number": 9,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.SourceCodeInfo"
    }, {
      "name": "syntax",
      "number": 12,
      "type": 9,
      "label": 1
    }, {
      "name": "edition",
      "number": 14,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.Edition"
    }]
  }, {
    "name": "DescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "field",
      "number": 2,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FieldDescriptorProto"
    }, {
      "name": "extension",
      "number": 6,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FieldDescriptorProto"
    }, {
      "name": "nested_type",
      "number": 3,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.DescriptorProto"
    }, {
      "name": "enum_type",
      "number": 4,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.EnumDescriptorProto"
    }, {
      "name": "extension_range",
      "number": 5,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.DescriptorProto.ExtensionRange"
    }, {
      "name": "oneof_decl",
      "number": 8,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.OneofDescriptorProto"
    }, {
      "name": "options",
      "number": 7,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.MessageOptions"
    }, {
      "name": "reserved_range",
      "number": 9,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.DescriptorProto.ReservedRange"
    }, {
      "name": "reserved_name",
      "number": 10,
      "type": 9,
      "label": 3
    }],
    "nestedType": [{
      "name": "ExtensionRange",
      "field": [{
        "name": "start",
        "number": 1,
        "type": 5,
        "label": 1
      }, {
        "name": "end",
        "number": 2,
        "type": 5,
        "label": 1
      }, {
        "name": "options",
        "number": 3,
        "type": 11,
        "label": 1,
        "typeName": ".google.protobuf.ExtensionRangeOptions"
      }]
    }, {
      "name": "ReservedRange",
      "field": [{
        "name": "start",
        "number": 1,
        "type": 5,
        "label": 1
      }, {
        "name": "end",
        "number": 2,
        "type": 5,
        "label": 1
      }]
    }]
  }, {
    "name": "ExtensionRangeOptions",
    "field": [{
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }, {
      "name": "declaration",
      "number": 2,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.ExtensionRangeOptions.Declaration",
      "options": {
        "retention": 2
      }
    }, {
      "name": "features",
      "number": 50,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "verification",
      "number": 3,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.ExtensionRangeOptions.VerificationState",
      "defaultValue": "UNVERIFIED",
      "options": {
        "retention": 2
      }
    }],
    "nestedType": [{
      "name": "Declaration",
      "field": [{
        "name": "number",
        "number": 1,
        "type": 5,
        "label": 1
      }, {
        "name": "full_name",
        "number": 2,
        "type": 9,
        "label": 1
      }, {
        "name": "type",
        "number": 3,
        "type": 9,
        "label": 1
      }, {
        "name": "reserved",
        "number": 5,
        "type": 8,
        "label": 1
      }, {
        "name": "repeated",
        "number": 6,
        "type": 8,
        "label": 1
      }]
    }],
    "enumType": [{
      "name": "VerificationState",
      "value": [{
        "name": "DECLARATION",
        "number": 0
      }, {
        "name": "UNVERIFIED",
        "number": 1
      }]
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "FieldDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "number",
      "number": 3,
      "type": 5,
      "label": 1
    }, {
      "name": "label",
      "number": 4,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FieldDescriptorProto.Label"
    }, {
      "name": "type",
      "number": 5,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FieldDescriptorProto.Type"
    }, {
      "name": "type_name",
      "number": 6,
      "type": 9,
      "label": 1
    }, {
      "name": "extendee",
      "number": 2,
      "type": 9,
      "label": 1
    }, {
      "name": "default_value",
      "number": 7,
      "type": 9,
      "label": 1
    }, {
      "name": "oneof_index",
      "number": 9,
      "type": 5,
      "label": 1
    }, {
      "name": "json_name",
      "number": 10,
      "type": 9,
      "label": 1
    }, {
      "name": "options",
      "number": 8,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions"
    }, {
      "name": "proto3_optional",
      "number": 17,
      "type": 8,
      "label": 1
    }],
    "enumType": [{
      "name": "Type",
      "value": [{
        "name": "TYPE_DOUBLE",
        "number": 1
      }, {
        "name": "TYPE_FLOAT",
        "number": 2
      }, {
        "name": "TYPE_INT64",
        "number": 3
      }, {
        "name": "TYPE_UINT64",
        "number": 4
      }, {
        "name": "TYPE_INT32",
        "number": 5
      }, {
        "name": "TYPE_FIXED64",
        "number": 6
      }, {
        "name": "TYPE_FIXED32",
        "number": 7
      }, {
        "name": "TYPE_BOOL",
        "number": 8
      }, {
        "name": "TYPE_STRING",
        "number": 9
      }, {
        "name": "TYPE_GROUP",
        "number": 10
      }, {
        "name": "TYPE_MESSAGE",
        "number": 11
      }, {
        "name": "TYPE_BYTES",
        "number": 12
      }, {
        "name": "TYPE_UINT32",
        "number": 13
      }, {
        "name": "TYPE_ENUM",
        "number": 14
      }, {
        "name": "TYPE_SFIXED32",
        "number": 15
      }, {
        "name": "TYPE_SFIXED64",
        "number": 16
      }, {
        "name": "TYPE_SINT32",
        "number": 17
      }, {
        "name": "TYPE_SINT64",
        "number": 18
      }]
    }, {
      "name": "Label",
      "value": [{
        "name": "LABEL_OPTIONAL",
        "number": 1
      }, {
        "name": "LABEL_REPEATED",
        "number": 3
      }, {
        "name": "LABEL_REQUIRED",
        "number": 2
      }]
    }]
  }, {
    "name": "OneofDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "options",
      "number": 2,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.OneofOptions"
    }]
  }, {
    "name": "EnumDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "value",
      "number": 2,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.EnumValueDescriptorProto"
    }, {
      "name": "options",
      "number": 3,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.EnumOptions"
    }, {
      "name": "reserved_range",
      "number": 4,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.EnumDescriptorProto.EnumReservedRange"
    }, {
      "name": "reserved_name",
      "number": 5,
      "type": 9,
      "label": 3
    }],
    "nestedType": [{
      "name": "EnumReservedRange",
      "field": [{
        "name": "start",
        "number": 1,
        "type": 5,
        "label": 1
      }, {
        "name": "end",
        "number": 2,
        "type": 5,
        "label": 1
      }]
    }]
  }, {
    "name": "EnumValueDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "number",
      "number": 2,
      "type": 5,
      "label": 1
    }, {
      "name": "options",
      "number": 3,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.EnumValueOptions"
    }]
  }, {
    "name": "ServiceDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "method",
      "number": 2,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.MethodDescriptorProto"
    }, {
      "name": "options",
      "number": 3,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.ServiceOptions"
    }]
  }, {
    "name": "MethodDescriptorProto",
    "field": [{
      "name": "name",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "input_type",
      "number": 2,
      "type": 9,
      "label": 1
    }, {
      "name": "output_type",
      "number": 3,
      "type": 9,
      "label": 1
    }, {
      "name": "options",
      "number": 4,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.MethodOptions"
    }, {
      "name": "client_streaming",
      "number": 5,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "server_streaming",
      "number": 6,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }]
  }, {
    "name": "FileOptions",
    "field": [{
      "name": "java_package",
      "number": 1,
      "type": 9,
      "label": 1
    }, {
      "name": "java_outer_classname",
      "number": 8,
      "type": 9,
      "label": 1
    }, {
      "name": "java_multiple_files",
      "number": 10,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "java_generate_equals_and_hash",
      "number": 20,
      "type": 8,
      "label": 1,
      "options": {
        "deprecated": true
      }
    }, {
      "name": "java_string_check_utf8",
      "number": 27,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "optimize_for",
      "number": 9,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FileOptions.OptimizeMode",
      "defaultValue": "SPEED"
    }, {
      "name": "go_package",
      "number": 11,
      "type": 9,
      "label": 1
    }, {
      "name": "cc_generic_services",
      "number": 16,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "java_generic_services",
      "number": 17,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "py_generic_services",
      "number": 18,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "deprecated",
      "number": 23,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "cc_enable_arenas",
      "number": 31,
      "type": 8,
      "label": 1,
      "defaultValue": "true"
    }, {
      "name": "objc_class_prefix",
      "number": 36,
      "type": 9,
      "label": 1
    }, {
      "name": "csharp_namespace",
      "number": 37,
      "type": 9,
      "label": 1
    }, {
      "name": "swift_prefix",
      "number": 39,
      "type": 9,
      "label": 1
    }, {
      "name": "php_class_prefix",
      "number": 40,
      "type": 9,
      "label": 1
    }, {
      "name": "php_namespace",
      "number": 41,
      "type": 9,
      "label": 1
    }, {
      "name": "php_metadata_namespace",
      "number": 44,
      "type": 9,
      "label": 1
    }, {
      "name": "ruby_package",
      "number": 45,
      "type": 9,
      "label": 1
    }, {
      "name": "features",
      "number": 50,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "enumType": [{
      "name": "OptimizeMode",
      "value": [{
        "name": "SPEED",
        "number": 1
      }, {
        "name": "CODE_SIZE",
        "number": 2
      }, {
        "name": "LITE_RUNTIME",
        "number": 3
      }]
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "MessageOptions",
    "field": [{
      "name": "message_set_wire_format",
      "number": 1,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "no_standard_descriptor_accessor",
      "number": 2,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "deprecated",
      "number": 3,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "map_entry",
      "number": 7,
      "type": 8,
      "label": 1
    }, {
      "name": "deprecated_legacy_json_field_conflicts",
      "number": 11,
      "type": 8,
      "label": 1,
      "options": {
        "deprecated": true
      }
    }, {
      "name": "features",
      "number": 12,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "FieldOptions",
    "field": [{
      "name": "ctype",
      "number": 1,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions.CType",
      "defaultValue": "STRING"
    }, {
      "name": "packed",
      "number": 2,
      "type": 8,
      "label": 1
    }, {
      "name": "jstype",
      "number": 6,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions.JSType",
      "defaultValue": "JS_NORMAL"
    }, {
      "name": "lazy",
      "number": 5,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "unverified_lazy",
      "number": 15,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "deprecated",
      "number": 3,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "weak",
      "number": 10,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "debug_redact",
      "number": 16,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "retention",
      "number": 17,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions.OptionRetention"
    }, {
      "name": "targets",
      "number": 19,
      "type": 14,
      "label": 3,
      "typeName": ".google.protobuf.FieldOptions.OptionTargetType"
    }, {
      "name": "edition_defaults",
      "number": 20,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FieldOptions.EditionDefault"
    }, {
      "name": "features",
      "number": 21,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "feature_support",
      "number": 22,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions.FeatureSupport"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "nestedType": [{
      "name": "EditionDefault",
      "field": [{
        "name": "edition",
        "number": 3,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.Edition"
      }, {
        "name": "value",
        "number": 2,
        "type": 9,
        "label": 1
      }]
    }, {
      "name": "FeatureSupport",
      "field": [{
        "name": "edition_introduced",
        "number": 1,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.Edition"
      }, {
        "name": "edition_deprecated",
        "number": 2,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.Edition"
      }, {
        "name": "deprecation_warning",
        "number": 3,
        "type": 9,
        "label": 1
      }, {
        "name": "edition_removed",
        "number": 4,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.Edition"
      }]
    }],
    "enumType": [{
      "name": "CType",
      "value": [{
        "name": "STRING",
        "number": 0
      }, {
        "name": "CORD",
        "number": 1
      }, {
        "name": "STRING_PIECE",
        "number": 2
      }]
    }, {
      "name": "JSType",
      "value": [{
        "name": "JS_NORMAL",
        "number": 0
      }, {
        "name": "JS_STRING",
        "number": 1
      }, {
        "name": "JS_NUMBER",
        "number": 2
      }]
    }, {
      "name": "OptionRetention",
      "value": [{
        "name": "RETENTION_UNKNOWN",
        "number": 0
      }, {
        "name": "RETENTION_RUNTIME",
        "number": 1
      }, {
        "name": "RETENTION_SOURCE",
        "number": 2
      }]
    }, {
      "name": "OptionTargetType",
      "value": [{
        "name": "TARGET_TYPE_UNKNOWN",
        "number": 0
      }, {
        "name": "TARGET_TYPE_FILE",
        "number": 1
      }, {
        "name": "TARGET_TYPE_EXTENSION_RANGE",
        "number": 2
      }, {
        "name": "TARGET_TYPE_MESSAGE",
        "number": 3
      }, {
        "name": "TARGET_TYPE_FIELD",
        "number": 4
      }, {
        "name": "TARGET_TYPE_ONEOF",
        "number": 5
      }, {
        "name": "TARGET_TYPE_ENUM",
        "number": 6
      }, {
        "name": "TARGET_TYPE_ENUM_ENTRY",
        "number": 7
      }, {
        "name": "TARGET_TYPE_SERVICE",
        "number": 8
      }, {
        "name": "TARGET_TYPE_METHOD",
        "number": 9
      }]
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "OneofOptions",
    "field": [{
      "name": "features",
      "number": 1,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "EnumOptions",
    "field": [{
      "name": "allow_alias",
      "number": 2,
      "type": 8,
      "label": 1
    }, {
      "name": "deprecated",
      "number": 3,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "deprecated_legacy_json_field_conflicts",
      "number": 6,
      "type": 8,
      "label": 1,
      "options": {
        "deprecated": true
      }
    }, {
      "name": "features",
      "number": 7,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "EnumValueOptions",
    "field": [{
      "name": "deprecated",
      "number": 1,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "features",
      "number": 2,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "debug_redact",
      "number": 3,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "feature_support",
      "number": 4,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FieldOptions.FeatureSupport"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "ServiceOptions",
    "field": [{
      "name": "features",
      "number": 34,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "deprecated",
      "number": 33,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "MethodOptions",
    "field": [{
      "name": "deprecated",
      "number": 33,
      "type": 8,
      "label": 1,
      "defaultValue": "false"
    }, {
      "name": "idempotency_level",
      "number": 34,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.MethodOptions.IdempotencyLevel",
      "defaultValue": "IDEMPOTENCY_UNKNOWN"
    }, {
      "name": "features",
      "number": 35,
      "type": 11,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet"
    }, {
      "name": "uninterpreted_option",
      "number": 999,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption"
    }],
    "enumType": [{
      "name": "IdempotencyLevel",
      "value": [{
        "name": "IDEMPOTENCY_UNKNOWN",
        "number": 0
      }, {
        "name": "NO_SIDE_EFFECTS",
        "number": 1
      }, {
        "name": "IDEMPOTENT",
        "number": 2
      }]
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 536870912
    }]
  }, {
    "name": "UninterpretedOption",
    "field": [{
      "name": "name",
      "number": 2,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.UninterpretedOption.NamePart"
    }, {
      "name": "identifier_value",
      "number": 3,
      "type": 9,
      "label": 1
    }, {
      "name": "positive_int_value",
      "number": 4,
      "type": 4,
      "label": 1
    }, {
      "name": "negative_int_value",
      "number": 5,
      "type": 3,
      "label": 1
    }, {
      "name": "double_value",
      "number": 6,
      "type": 1,
      "label": 1
    }, {
      "name": "string_value",
      "number": 7,
      "type": 12,
      "label": 1
    }, {
      "name": "aggregate_value",
      "number": 8,
      "type": 9,
      "label": 1
    }],
    "nestedType": [{
      "name": "NamePart",
      "field": [{
        "name": "name_part",
        "number": 1,
        "type": 9,
        "label": 2
      }, {
        "name": "is_extension",
        "number": 2,
        "type": 8,
        "label": 2
      }]
    }]
  }, {
    "name": "FeatureSet",
    "field": [{
      "name": "field_presence",
      "number": 1,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.FieldPresence",
      "options": {
        "retention": 1,
        "targets": [4, 1],
        "editionDefaults": [{
          "value": "EXPLICIT",
          "edition": 900
        }, {
          "value": "IMPLICIT",
          "edition": 999
        }, {
          "value": "EXPLICIT",
          "edition": 1000
        }]
      }
    }, {
      "name": "enum_type",
      "number": 2,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.EnumType",
      "options": {
        "retention": 1,
        "targets": [6, 1],
        "editionDefaults": [{
          "value": "CLOSED",
          "edition": 900
        }, {
          "value": "OPEN",
          "edition": 999
        }]
      }
    }, {
      "name": "repeated_field_encoding",
      "number": 3,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.RepeatedFieldEncoding",
      "options": {
        "retention": 1,
        "targets": [4, 1],
        "editionDefaults": [{
          "value": "EXPANDED",
          "edition": 900
        }, {
          "value": "PACKED",
          "edition": 999
        }]
      }
    }, {
      "name": "utf8_validation",
      "number": 4,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.Utf8Validation",
      "options": {
        "retention": 1,
        "targets": [4, 1],
        "editionDefaults": [{
          "value": "NONE",
          "edition": 900
        }, {
          "value": "VERIFY",
          "edition": 999
        }]
      }
    }, {
      "name": "message_encoding",
      "number": 5,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.MessageEncoding",
      "options": {
        "retention": 1,
        "targets": [4, 1],
        "editionDefaults": [{
          "value": "LENGTH_PREFIXED",
          "edition": 900
        }]
      }
    }, {
      "name": "json_format",
      "number": 6,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.FeatureSet.JsonFormat",
      "options": {
        "retention": 1,
        "targets": [3, 6, 1],
        "editionDefaults": [{
          "value": "LEGACY_BEST_EFFORT",
          "edition": 900
        }, {
          "value": "ALLOW",
          "edition": 999
        }]
      }
    }],
    "enumType": [{
      "name": "FieldPresence",
      "value": [{
        "name": "FIELD_PRESENCE_UNKNOWN",
        "number": 0
      }, {
        "name": "EXPLICIT",
        "number": 1
      }, {
        "name": "IMPLICIT",
        "number": 2
      }, {
        "name": "LEGACY_REQUIRED",
        "number": 3
      }]
    }, {
      "name": "EnumType",
      "value": [{
        "name": "ENUM_TYPE_UNKNOWN",
        "number": 0
      }, {
        "name": "OPEN",
        "number": 1
      }, {
        "name": "CLOSED",
        "number": 2
      }]
    }, {
      "name": "RepeatedFieldEncoding",
      "value": [{
        "name": "REPEATED_FIELD_ENCODING_UNKNOWN",
        "number": 0
      }, {
        "name": "PACKED",
        "number": 1
      }, {
        "name": "EXPANDED",
        "number": 2
      }]
    }, {
      "name": "Utf8Validation",
      "value": [{
        "name": "UTF8_VALIDATION_UNKNOWN",
        "number": 0
      }, {
        "name": "VERIFY",
        "number": 2
      }, {
        "name": "NONE",
        "number": 3
      }]
    }, {
      "name": "MessageEncoding",
      "value": [{
        "name": "MESSAGE_ENCODING_UNKNOWN",
        "number": 0
      }, {
        "name": "LENGTH_PREFIXED",
        "number": 1
      }, {
        "name": "DELIMITED",
        "number": 2
      }]
    }, {
      "name": "JsonFormat",
      "value": [{
        "name": "JSON_FORMAT_UNKNOWN",
        "number": 0
      }, {
        "name": "ALLOW",
        "number": 1
      }, {
        "name": "LEGACY_BEST_EFFORT",
        "number": 2
      }]
    }],
    "extensionRange": [{
      "start": 1000,
      "end": 9995
    }, {
      "start": 9995,
      "end": 10000
    }, {
      "start": 10000,
      "end": 10001
    }]
  }, {
    "name": "FeatureSetDefaults",
    "field": [{
      "name": "defaults",
      "number": 1,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault"
    }, {
      "name": "minimum_edition",
      "number": 4,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.Edition"
    }, {
      "name": "maximum_edition",
      "number": 5,
      "type": 14,
      "label": 1,
      "typeName": ".google.protobuf.Edition"
    }],
    "nestedType": [{
      "name": "FeatureSetEditionDefault",
      "field": [{
        "name": "edition",
        "number": 3,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.Edition"
      }, {
        "name": "overridable_features",
        "number": 4,
        "type": 11,
        "label": 1,
        "typeName": ".google.protobuf.FeatureSet"
      }, {
        "name": "fixed_features",
        "number": 5,
        "type": 11,
        "label": 1,
        "typeName": ".google.protobuf.FeatureSet"
      }]
    }]
  }, {
    "name": "SourceCodeInfo",
    "field": [{
      "name": "location",
      "number": 1,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.SourceCodeInfo.Location"
    }],
    "nestedType": [{
      "name": "Location",
      "field": [{
        "name": "path",
        "number": 1,
        "type": 5,
        "label": 3,
        "options": {
          "packed": true
        }
      }, {
        "name": "span",
        "number": 2,
        "type": 5,
        "label": 3,
        "options": {
          "packed": true
        }
      }, {
        "name": "leading_comments",
        "number": 3,
        "type": 9,
        "label": 1
      }, {
        "name": "trailing_comments",
        "number": 4,
        "type": 9,
        "label": 1
      }, {
        "name": "leading_detached_comments",
        "number": 6,
        "type": 9,
        "label": 3
      }]
    }]
  }, {
    "name": "GeneratedCodeInfo",
    "field": [{
      "name": "annotation",
      "number": 1,
      "type": 11,
      "label": 3,
      "typeName": ".google.protobuf.GeneratedCodeInfo.Annotation"
    }],
    "nestedType": [{
      "name": "Annotation",
      "field": [{
        "name": "path",
        "number": 1,
        "type": 5,
        "label": 3,
        "options": {
          "packed": true
        }
      }, {
        "name": "source_file",
        "number": 2,
        "type": 9,
        "label": 1
      }, {
        "name": "begin",
        "number": 3,
        "type": 5,
        "label": 1
      }, {
        "name": "end",
        "number": 4,
        "type": 5,
        "label": 1
      }, {
        "name": "semantic",
        "number": 5,
        "type": 14,
        "label": 1,
        "typeName": ".google.protobuf.GeneratedCodeInfo.Annotation.Semantic"
      }],
      "enumType": [{
        "name": "Semantic",
        "value": [{
          "name": "NONE",
          "number": 0
        }, {
          "name": "SET",
          "number": 1
        }, {
          "name": "ALIAS",
          "number": 2
        }]
      }]
    }]
  }],
  "enumType": [{
    "name": "Edition",
    "value": [{
      "name": "EDITION_UNKNOWN",
      "number": 0
    }, {
      "name": "EDITION_LEGACY",
      "number": 900
    }, {
      "name": "EDITION_PROTO2",
      "number": 998
    }, {
      "name": "EDITION_PROTO3",
      "number": 999
    }, {
      "name": "EDITION_2023",
      "number": 1000
    }, {
      "name": "EDITION_2024",
      "number": 1001
    }, {
      "name": "EDITION_1_TEST_ONLY",
      "number": 1
    }, {
      "name": "EDITION_2_TEST_ONLY",
      "number": 2
    }, {
      "name": "EDITION_99997_TEST_ONLY",
      "number": 99997
    }, {
      "name": "EDITION_99998_TEST_ONLY",
      "number": 99998
    }, {
      "name": "EDITION_99999_TEST_ONLY",
      "number": 99999
    }, {
      "name": "EDITION_MAX",
      "number": 2147483647
    }]
  }]
});
/**
 * Describes the message google.protobuf.FileDescriptorSet.
 * Use `create(FileDescriptorSetSchema)` to create a new message.
 */
export const FileDescriptorSetSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 0);
/**
 * Describes the message google.protobuf.FileDescriptorProto.
 * Use `create(FileDescriptorProtoSchema)` to create a new message.
 */
export const FileDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 1);
/**
 * Describes the message google.protobuf.DescriptorProto.
 * Use `create(DescriptorProtoSchema)` to create a new message.
 */
export const DescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 2);
/**
 * Describes the message google.protobuf.DescriptorProto.ExtensionRange.
 * Use `create(DescriptorProto_ExtensionRangeSchema)` to create a new message.
 */
export const DescriptorProto_ExtensionRangeSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 2, 0);
/**
 * Describes the message google.protobuf.DescriptorProto.ReservedRange.
 * Use `create(DescriptorProto_ReservedRangeSchema)` to create a new message.
 */
export const DescriptorProto_ReservedRangeSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 2, 1);
/**
 * Describes the message google.protobuf.ExtensionRangeOptions.
 * Use `create(ExtensionRangeOptionsSchema)` to create a new message.
 */
export const ExtensionRangeOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 3);
/**
 * Describes the message google.protobuf.ExtensionRangeOptions.Declaration.
 * Use `create(ExtensionRangeOptions_DeclarationSchema)` to create a new message.
 */
export const ExtensionRangeOptions_DeclarationSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 3, 0);
/**
 * The verification state of the extension range.
 *
 * @generated from enum google.protobuf.ExtensionRangeOptions.VerificationState
 */
export var ExtensionRangeOptions_VerificationState;
(function (ExtensionRangeOptions_VerificationState) {
  /**
   * All the extensions of the range must be declared.
   *
   * @generated from enum value: DECLARATION = 0;
   */
  ExtensionRangeOptions_VerificationState[ExtensionRangeOptions_VerificationState["DECLARATION"] = 0] = "DECLARATION";
  /**
   * @generated from enum value: UNVERIFIED = 1;
   */
  ExtensionRangeOptions_VerificationState[ExtensionRangeOptions_VerificationState["UNVERIFIED"] = 1] = "UNVERIFIED";
})(ExtensionRangeOptions_VerificationState || (ExtensionRangeOptions_VerificationState = {}));
/**
 * Describes the enum google.protobuf.ExtensionRangeOptions.VerificationState.
 */
export const ExtensionRangeOptions_VerificationStateSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 3, 0);
/**
 * Describes the message google.protobuf.FieldDescriptorProto.
 * Use `create(FieldDescriptorProtoSchema)` to create a new message.
 */
export const FieldDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 4);
/**
 * @generated from enum google.protobuf.FieldDescriptorProto.Type
 */
export var FieldDescriptorProto_Type;
(function (FieldDescriptorProto_Type) {
  /**
   * 0 is reserved for errors.
   * Order is weird for historical reasons.
   *
   * @generated from enum value: TYPE_DOUBLE = 1;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["DOUBLE"] = 1] = "DOUBLE";
  /**
   * @generated from enum value: TYPE_FLOAT = 2;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["FLOAT"] = 2] = "FLOAT";
  /**
   * Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT64 if
   * negative values are likely.
   *
   * @generated from enum value: TYPE_INT64 = 3;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["INT64"] = 3] = "INT64";
  /**
   * @generated from enum value: TYPE_UINT64 = 4;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["UINT64"] = 4] = "UINT64";
  /**
   * Not ZigZag encoded.  Negative numbers take 10 bytes.  Use TYPE_SINT32 if
   * negative values are likely.
   *
   * @generated from enum value: TYPE_INT32 = 5;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["INT32"] = 5] = "INT32";
  /**
   * @generated from enum value: TYPE_FIXED64 = 6;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["FIXED64"] = 6] = "FIXED64";
  /**
   * @generated from enum value: TYPE_FIXED32 = 7;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["FIXED32"] = 7] = "FIXED32";
  /**
   * @generated from enum value: TYPE_BOOL = 8;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["BOOL"] = 8] = "BOOL";
  /**
   * @generated from enum value: TYPE_STRING = 9;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["STRING"] = 9] = "STRING";
  /**
   * Tag-delimited aggregate.
   * Group type is deprecated and not supported after google.protobuf. However, Proto3
   * implementations should still be able to parse the group wire format and
   * treat group fields as unknown fields.  In Editions, the group wire format
   * can be enabled via the `message_encoding` feature.
   *
   * @generated from enum value: TYPE_GROUP = 10;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["GROUP"] = 10] = "GROUP";
  /**
   * Length-delimited aggregate.
   *
   * @generated from enum value: TYPE_MESSAGE = 11;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["MESSAGE"] = 11] = "MESSAGE";
  /**
   * New in version 2.
   *
   * @generated from enum value: TYPE_BYTES = 12;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["BYTES"] = 12] = "BYTES";
  /**
   * @generated from enum value: TYPE_UINT32 = 13;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["UINT32"] = 13] = "UINT32";
  /**
   * @generated from enum value: TYPE_ENUM = 14;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["ENUM"] = 14] = "ENUM";
  /**
   * @generated from enum value: TYPE_SFIXED32 = 15;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["SFIXED32"] = 15] = "SFIXED32";
  /**
   * @generated from enum value: TYPE_SFIXED64 = 16;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["SFIXED64"] = 16] = "SFIXED64";
  /**
   * Uses ZigZag encoding.
   *
   * @generated from enum value: TYPE_SINT32 = 17;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["SINT32"] = 17] = "SINT32";
  /**
   * Uses ZigZag encoding.
   *
   * @generated from enum value: TYPE_SINT64 = 18;
   */
  FieldDescriptorProto_Type[FieldDescriptorProto_Type["SINT64"] = 18] = "SINT64";
})(FieldDescriptorProto_Type || (FieldDescriptorProto_Type = {}));
/**
 * Describes the enum google.protobuf.FieldDescriptorProto.Type.
 */
export const FieldDescriptorProto_TypeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 4, 0);
/**
 * @generated from enum google.protobuf.FieldDescriptorProto.Label
 */
export var FieldDescriptorProto_Label;
(function (FieldDescriptorProto_Label) {
  /**
   * 0 is reserved for errors
   *
   * @generated from enum value: LABEL_OPTIONAL = 1;
   */
  FieldDescriptorProto_Label[FieldDescriptorProto_Label["OPTIONAL"] = 1] = "OPTIONAL";
  /**
   * @generated from enum value: LABEL_REPEATED = 3;
   */
  FieldDescriptorProto_Label[FieldDescriptorProto_Label["REPEATED"] = 3] = "REPEATED";
  /**
   * The required label is only allowed in google.protobuf.  In proto3 and Editions
   * it's explicitly prohibited.  In Editions, the `field_presence` feature
   * can be used to get this behavior.
   *
   * @generated from enum value: LABEL_REQUIRED = 2;
   */
  FieldDescriptorProto_Label[FieldDescriptorProto_Label["REQUIRED"] = 2] = "REQUIRED";
})(FieldDescriptorProto_Label || (FieldDescriptorProto_Label = {}));
/**
 * Describes the enum google.protobuf.FieldDescriptorProto.Label.
 */
export const FieldDescriptorProto_LabelSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 4, 1);
/**
 * Describes the message google.protobuf.OneofDescriptorProto.
 * Use `create(OneofDescriptorProtoSchema)` to create a new message.
 */
export const OneofDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 5);
/**
 * Describes the message google.protobuf.EnumDescriptorProto.
 * Use `create(EnumDescriptorProtoSchema)` to create a new message.
 */
export const EnumDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 6);
/**
 * Describes the message google.protobuf.EnumDescriptorProto.EnumReservedRange.
 * Use `create(EnumDescriptorProto_EnumReservedRangeSchema)` to create a new message.
 */
export const EnumDescriptorProto_EnumReservedRangeSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 6, 0);
/**
 * Describes the message google.protobuf.EnumValueDescriptorProto.
 * Use `create(EnumValueDescriptorProtoSchema)` to create a new message.
 */
export const EnumValueDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 7);
/**
 * Describes the message google.protobuf.ServiceDescriptorProto.
 * Use `create(ServiceDescriptorProtoSchema)` to create a new message.
 */
export const ServiceDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 8);
/**
 * Describes the message google.protobuf.MethodDescriptorProto.
 * Use `create(MethodDescriptorProtoSchema)` to create a new message.
 */
export const MethodDescriptorProtoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 9);
/**
 * Describes the message google.protobuf.FileOptions.
 * Use `create(FileOptionsSchema)` to create a new message.
 */
export const FileOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 10);
/**
 * Generated classes can be optimized for speed or code size.
 *
 * @generated from enum google.protobuf.FileOptions.OptimizeMode
 */
export var FileOptions_OptimizeMode;
(function (FileOptions_OptimizeMode) {
  /**
   * Generate complete code for parsing, serialization,
   *
   * @generated from enum value: SPEED = 1;
   */
  FileOptions_OptimizeMode[FileOptions_OptimizeMode["SPEED"] = 1] = "SPEED";
  /**
   * etc.
   *
   * Use ReflectionOps to implement these methods.
   *
   * @generated from enum value: CODE_SIZE = 2;
   */
  FileOptions_OptimizeMode[FileOptions_OptimizeMode["CODE_SIZE"] = 2] = "CODE_SIZE";
  /**
   * Generate code using MessageLite and the lite runtime.
   *
   * @generated from enum value: LITE_RUNTIME = 3;
   */
  FileOptions_OptimizeMode[FileOptions_OptimizeMode["LITE_RUNTIME"] = 3] = "LITE_RUNTIME";
})(FileOptions_OptimizeMode || (FileOptions_OptimizeMode = {}));
/**
 * Describes the enum google.protobuf.FileOptions.OptimizeMode.
 */
export const FileOptions_OptimizeModeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 10, 0);
/**
 * Describes the message google.protobuf.MessageOptions.
 * Use `create(MessageOptionsSchema)` to create a new message.
 */
export const MessageOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 11);
/**
 * Describes the message google.protobuf.FieldOptions.
 * Use `create(FieldOptionsSchema)` to create a new message.
 */
export const FieldOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 12);
/**
 * Describes the message google.protobuf.FieldOptions.EditionDefault.
 * Use `create(FieldOptions_EditionDefaultSchema)` to create a new message.
 */
export const FieldOptions_EditionDefaultSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 12, 0);
/**
 * Describes the message google.protobuf.FieldOptions.FeatureSupport.
 * Use `create(FieldOptions_FeatureSupportSchema)` to create a new message.
 */
export const FieldOptions_FeatureSupportSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 12, 1);
/**
 * @generated from enum google.protobuf.FieldOptions.CType
 */
export var FieldOptions_CType;
(function (FieldOptions_CType) {
  /**
   * Default mode.
   *
   * @generated from enum value: STRING = 0;
   */
  FieldOptions_CType[FieldOptions_CType["STRING"] = 0] = "STRING";
  /**
   * The option [ctype=CORD] may be applied to a non-repeated field of type
   * "bytes". It indicates that in C++, the data should be stored in a Cord
   * instead of a string.  For very large strings, this may reduce memory
   * fragmentation. It may also allow better performance when parsing from a
   * Cord, or when parsing with aliasing enabled, as the parsed Cord may then
   * alias the original buffer.
   *
   * @generated from enum value: CORD = 1;
   */
  FieldOptions_CType[FieldOptions_CType["CORD"] = 1] = "CORD";
  /**
   * @generated from enum value: STRING_PIECE = 2;
   */
  FieldOptions_CType[FieldOptions_CType["STRING_PIECE"] = 2] = "STRING_PIECE";
})(FieldOptions_CType || (FieldOptions_CType = {}));
/**
 * Describes the enum google.protobuf.FieldOptions.CType.
 */
export const FieldOptions_CTypeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 12, 0);
/**
 * @generated from enum google.protobuf.FieldOptions.JSType
 */
export var FieldOptions_JSType;
(function (FieldOptions_JSType) {
  /**
   * Use the default type.
   *
   * @generated from enum value: JS_NORMAL = 0;
   */
  FieldOptions_JSType[FieldOptions_JSType["JS_NORMAL"] = 0] = "JS_NORMAL";
  /**
   * Use JavaScript strings.
   *
   * @generated from enum value: JS_STRING = 1;
   */
  FieldOptions_JSType[FieldOptions_JSType["JS_STRING"] = 1] = "JS_STRING";
  /**
   * Use JavaScript numbers.
   *
   * @generated from enum value: JS_NUMBER = 2;
   */
  FieldOptions_JSType[FieldOptions_JSType["JS_NUMBER"] = 2] = "JS_NUMBER";
})(FieldOptions_JSType || (FieldOptions_JSType = {}));
/**
 * Describes the enum google.protobuf.FieldOptions.JSType.
 */
export const FieldOptions_JSTypeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 12, 1);
/**
 * If set to RETENTION_SOURCE, the option will be omitted from the binary.
 * Note: as of January 2023, support for this is in progress and does not yet
 * have an effect (b/264593489).
 *
 * @generated from enum google.protobuf.FieldOptions.OptionRetention
 */
export var FieldOptions_OptionRetention;
(function (FieldOptions_OptionRetention) {
  /**
   * @generated from enum value: RETENTION_UNKNOWN = 0;
   */
  FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_UNKNOWN"] = 0] = "RETENTION_UNKNOWN";
  /**
   * @generated from enum value: RETENTION_RUNTIME = 1;
   */
  FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_RUNTIME"] = 1] = "RETENTION_RUNTIME";
  /**
   * @generated from enum value: RETENTION_SOURCE = 2;
   */
  FieldOptions_OptionRetention[FieldOptions_OptionRetention["RETENTION_SOURCE"] = 2] = "RETENTION_SOURCE";
})(FieldOptions_OptionRetention || (FieldOptions_OptionRetention = {}));
/**
 * Describes the enum google.protobuf.FieldOptions.OptionRetention.
 */
export const FieldOptions_OptionRetentionSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 12, 2);
/**
 * This indicates the types of entities that the field may apply to when used
 * as an option. If it is unset, then the field may be freely used as an
 * option on any kind of entity. Note: as of January 2023, support for this is
 * in progress and does not yet have an effect (b/264593489).
 *
 * @generated from enum google.protobuf.FieldOptions.OptionTargetType
 */
export var FieldOptions_OptionTargetType;
(function (FieldOptions_OptionTargetType) {
  /**
   * @generated from enum value: TARGET_TYPE_UNKNOWN = 0;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_UNKNOWN"] = 0] = "TARGET_TYPE_UNKNOWN";
  /**
   * @generated from enum value: TARGET_TYPE_FILE = 1;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_FILE"] = 1] = "TARGET_TYPE_FILE";
  /**
   * @generated from enum value: TARGET_TYPE_EXTENSION_RANGE = 2;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_EXTENSION_RANGE"] = 2] = "TARGET_TYPE_EXTENSION_RANGE";
  /**
   * @generated from enum value: TARGET_TYPE_MESSAGE = 3;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_MESSAGE"] = 3] = "TARGET_TYPE_MESSAGE";
  /**
   * @generated from enum value: TARGET_TYPE_FIELD = 4;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_FIELD"] = 4] = "TARGET_TYPE_FIELD";
  /**
   * @generated from enum value: TARGET_TYPE_ONEOF = 5;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ONEOF"] = 5] = "TARGET_TYPE_ONEOF";
  /**
   * @generated from enum value: TARGET_TYPE_ENUM = 6;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ENUM"] = 6] = "TARGET_TYPE_ENUM";
  /**
   * @generated from enum value: TARGET_TYPE_ENUM_ENTRY = 7;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_ENUM_ENTRY"] = 7] = "TARGET_TYPE_ENUM_ENTRY";
  /**
   * @generated from enum value: TARGET_TYPE_SERVICE = 8;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_SERVICE"] = 8] = "TARGET_TYPE_SERVICE";
  /**
   * @generated from enum value: TARGET_TYPE_METHOD = 9;
   */
  FieldOptions_OptionTargetType[FieldOptions_OptionTargetType["TARGET_TYPE_METHOD"] = 9] = "TARGET_TYPE_METHOD";
})(FieldOptions_OptionTargetType || (FieldOptions_OptionTargetType = {}));
/**
 * Describes the enum google.protobuf.FieldOptions.OptionTargetType.
 */
export const FieldOptions_OptionTargetTypeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 12, 3);
/**
 * Describes the message google.protobuf.OneofOptions.
 * Use `create(OneofOptionsSchema)` to create a new message.
 */
export const OneofOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 13);
/**
 * Describes the message google.protobuf.EnumOptions.
 * Use `create(EnumOptionsSchema)` to create a new message.
 */
export const EnumOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 14);
/**
 * Describes the message google.protobuf.EnumValueOptions.
 * Use `create(EnumValueOptionsSchema)` to create a new message.
 */
export const EnumValueOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 15);
/**
 * Describes the message google.protobuf.ServiceOptions.
 * Use `create(ServiceOptionsSchema)` to create a new message.
 */
export const ServiceOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 16);
/**
 * Describes the message google.protobuf.MethodOptions.
 * Use `create(MethodOptionsSchema)` to create a new message.
 */
export const MethodOptionsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 17);
/**
 * Is this method side-effect-free (or safe in HTTP parlance), or idempotent,
 * or neither? HTTP based RPC implementation may choose GET verb for safe
 * methods, and PUT verb for idempotent methods instead of the default POST.
 *
 * @generated from enum google.protobuf.MethodOptions.IdempotencyLevel
 */
export var MethodOptions_IdempotencyLevel;
(function (MethodOptions_IdempotencyLevel) {
  /**
   * @generated from enum value: IDEMPOTENCY_UNKNOWN = 0;
   */
  MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["IDEMPOTENCY_UNKNOWN"] = 0] = "IDEMPOTENCY_UNKNOWN";
  /**
   * implies idempotent
   *
   * @generated from enum value: NO_SIDE_EFFECTS = 1;
   */
  MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["NO_SIDE_EFFECTS"] = 1] = "NO_SIDE_EFFECTS";
  /**
   * idempotent, but may have side effects
   *
   * @generated from enum value: IDEMPOTENT = 2;
   */
  MethodOptions_IdempotencyLevel[MethodOptions_IdempotencyLevel["IDEMPOTENT"] = 2] = "IDEMPOTENT";
})(MethodOptions_IdempotencyLevel || (MethodOptions_IdempotencyLevel = {}));
/**
 * Describes the enum google.protobuf.MethodOptions.IdempotencyLevel.
 */
export const MethodOptions_IdempotencyLevelSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 17, 0);
/**
 * Describes the message google.protobuf.UninterpretedOption.
 * Use `create(UninterpretedOptionSchema)` to create a new message.
 */
export const UninterpretedOptionSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 18);
/**
 * Describes the message google.protobuf.UninterpretedOption.NamePart.
 * Use `create(UninterpretedOption_NamePartSchema)` to create a new message.
 */
export const UninterpretedOption_NamePartSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 18, 0);
/**
 * Describes the message google.protobuf.FeatureSet.
 * Use `create(FeatureSetSchema)` to create a new message.
 */
export const FeatureSetSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 19);
/**
 * @generated from enum google.protobuf.FeatureSet.FieldPresence
 */
export var FeatureSet_FieldPresence;
(function (FeatureSet_FieldPresence) {
  /**
   * @generated from enum value: FIELD_PRESENCE_UNKNOWN = 0;
   */
  FeatureSet_FieldPresence[FeatureSet_FieldPresence["FIELD_PRESENCE_UNKNOWN"] = 0] = "FIELD_PRESENCE_UNKNOWN";
  /**
   * @generated from enum value: EXPLICIT = 1;
   */
  FeatureSet_FieldPresence[FeatureSet_FieldPresence["EXPLICIT"] = 1] = "EXPLICIT";
  /**
   * @generated from enum value: IMPLICIT = 2;
   */
  FeatureSet_FieldPresence[FeatureSet_FieldPresence["IMPLICIT"] = 2] = "IMPLICIT";
  /**
   * @generated from enum value: LEGACY_REQUIRED = 3;
   */
  FeatureSet_FieldPresence[FeatureSet_FieldPresence["LEGACY_REQUIRED"] = 3] = "LEGACY_REQUIRED";
})(FeatureSet_FieldPresence || (FeatureSet_FieldPresence = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.FieldPresence.
 */
export const FeatureSet_FieldPresenceSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 0);
/**
 * @generated from enum google.protobuf.FeatureSet.EnumType
 */
export var FeatureSet_EnumType;
(function (FeatureSet_EnumType) {
  /**
   * @generated from enum value: ENUM_TYPE_UNKNOWN = 0;
   */
  FeatureSet_EnumType[FeatureSet_EnumType["ENUM_TYPE_UNKNOWN"] = 0] = "ENUM_TYPE_UNKNOWN";
  /**
   * @generated from enum value: OPEN = 1;
   */
  FeatureSet_EnumType[FeatureSet_EnumType["OPEN"] = 1] = "OPEN";
  /**
   * @generated from enum value: CLOSED = 2;
   */
  FeatureSet_EnumType[FeatureSet_EnumType["CLOSED"] = 2] = "CLOSED";
})(FeatureSet_EnumType || (FeatureSet_EnumType = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.EnumType.
 */
export const FeatureSet_EnumTypeSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 1);
/**
 * @generated from enum google.protobuf.FeatureSet.RepeatedFieldEncoding
 */
export var FeatureSet_RepeatedFieldEncoding;
(function (FeatureSet_RepeatedFieldEncoding) {
  /**
   * @generated from enum value: REPEATED_FIELD_ENCODING_UNKNOWN = 0;
   */
  FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["REPEATED_FIELD_ENCODING_UNKNOWN"] = 0] = "REPEATED_FIELD_ENCODING_UNKNOWN";
  /**
   * @generated from enum value: PACKED = 1;
   */
  FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["PACKED"] = 1] = "PACKED";
  /**
   * @generated from enum value: EXPANDED = 2;
   */
  FeatureSet_RepeatedFieldEncoding[FeatureSet_RepeatedFieldEncoding["EXPANDED"] = 2] = "EXPANDED";
})(FeatureSet_RepeatedFieldEncoding || (FeatureSet_RepeatedFieldEncoding = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.RepeatedFieldEncoding.
 */
export const FeatureSet_RepeatedFieldEncodingSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 2);
/**
 * @generated from enum google.protobuf.FeatureSet.Utf8Validation
 */
export var FeatureSet_Utf8Validation;
(function (FeatureSet_Utf8Validation) {
  /**
   * @generated from enum value: UTF8_VALIDATION_UNKNOWN = 0;
   */
  FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["UTF8_VALIDATION_UNKNOWN"] = 0] = "UTF8_VALIDATION_UNKNOWN";
  /**
   * @generated from enum value: VERIFY = 2;
   */
  FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["VERIFY"] = 2] = "VERIFY";
  /**
   * @generated from enum value: NONE = 3;
   */
  FeatureSet_Utf8Validation[FeatureSet_Utf8Validation["NONE"] = 3] = "NONE";
})(FeatureSet_Utf8Validation || (FeatureSet_Utf8Validation = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.Utf8Validation.
 */
export const FeatureSet_Utf8ValidationSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 3);
/**
 * @generated from enum google.protobuf.FeatureSet.MessageEncoding
 */
export var FeatureSet_MessageEncoding;
(function (FeatureSet_MessageEncoding) {
  /**
   * @generated from enum value: MESSAGE_ENCODING_UNKNOWN = 0;
   */
  FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["MESSAGE_ENCODING_UNKNOWN"] = 0] = "MESSAGE_ENCODING_UNKNOWN";
  /**
   * @generated from enum value: LENGTH_PREFIXED = 1;
   */
  FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["LENGTH_PREFIXED"] = 1] = "LENGTH_PREFIXED";
  /**
   * @generated from enum value: DELIMITED = 2;
   */
  FeatureSet_MessageEncoding[FeatureSet_MessageEncoding["DELIMITED"] = 2] = "DELIMITED";
})(FeatureSet_MessageEncoding || (FeatureSet_MessageEncoding = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.MessageEncoding.
 */
export const FeatureSet_MessageEncodingSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 4);
/**
 * @generated from enum google.protobuf.FeatureSet.JsonFormat
 */
export var FeatureSet_JsonFormat;
(function (FeatureSet_JsonFormat) {
  /**
   * @generated from enum value: JSON_FORMAT_UNKNOWN = 0;
   */
  FeatureSet_JsonFormat[FeatureSet_JsonFormat["JSON_FORMAT_UNKNOWN"] = 0] = "JSON_FORMAT_UNKNOWN";
  /**
   * @generated from enum value: ALLOW = 1;
   */
  FeatureSet_JsonFormat[FeatureSet_JsonFormat["ALLOW"] = 1] = "ALLOW";
  /**
   * @generated from enum value: LEGACY_BEST_EFFORT = 2;
   */
  FeatureSet_JsonFormat[FeatureSet_JsonFormat["LEGACY_BEST_EFFORT"] = 2] = "LEGACY_BEST_EFFORT";
})(FeatureSet_JsonFormat || (FeatureSet_JsonFormat = {}));
/**
 * Describes the enum google.protobuf.FeatureSet.JsonFormat.
 */
export const FeatureSet_JsonFormatSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 19, 5);
/**
 * Describes the message google.protobuf.FeatureSetDefaults.
 * Use `create(FeatureSetDefaultsSchema)` to create a new message.
 */
export const FeatureSetDefaultsSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 20);
/**
 * Describes the message google.protobuf.FeatureSetDefaults.FeatureSetEditionDefault.
 * Use `create(FeatureSetDefaults_FeatureSetEditionDefaultSchema)` to create a new message.
 */
export const FeatureSetDefaults_FeatureSetEditionDefaultSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 20, 0);
/**
 * Describes the message google.protobuf.SourceCodeInfo.
 * Use `create(SourceCodeInfoSchema)` to create a new message.
 */
export const SourceCodeInfoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 21);
/**
 * Describes the message google.protobuf.SourceCodeInfo.Location.
 * Use `create(SourceCodeInfo_LocationSchema)` to create a new message.
 */
export const SourceCodeInfo_LocationSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 21, 0);
/**
 * Describes the message google.protobuf.GeneratedCodeInfo.
 * Use `create(GeneratedCodeInfoSchema)` to create a new message.
 */
export const GeneratedCodeInfoSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 22);
/**
 * Describes the message google.protobuf.GeneratedCodeInfo.Annotation.
 * Use `create(GeneratedCodeInfo_AnnotationSchema)` to create a new message.
 */
export const GeneratedCodeInfo_AnnotationSchema = /*@__PURE__*/messageDesc(file_google_protobuf_descriptor, 22, 0);
/**
 * Represents the identified object's effect on the element in the original
 * .proto file.
 *
 * @generated from enum google.protobuf.GeneratedCodeInfo.Annotation.Semantic
 */
export var GeneratedCodeInfo_Annotation_Semantic;
(function (GeneratedCodeInfo_Annotation_Semantic) {
  /**
   * There is no effect or the effect is indescribable.
   *
   * @generated from enum value: NONE = 0;
   */
  GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["NONE"] = 0] = "NONE";
  /**
   * The element is set or otherwise mutated.
   *
   * @generated from enum value: SET = 1;
   */
  GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["SET"] = 1] = "SET";
  /**
   * An alias to the element is returned.
   *
   * @generated from enum value: ALIAS = 2;
   */
  GeneratedCodeInfo_Annotation_Semantic[GeneratedCodeInfo_Annotation_Semantic["ALIAS"] = 2] = "ALIAS";
})(GeneratedCodeInfo_Annotation_Semantic || (GeneratedCodeInfo_Annotation_Semantic = {}));
/**
 * Describes the enum google.protobuf.GeneratedCodeInfo.Annotation.Semantic.
 */
export const GeneratedCodeInfo_Annotation_SemanticSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 22, 0, 0);
/**
 * The full set of known editions.
 *
 * @generated from enum google.protobuf.Edition
 */
export var Edition;
(function (Edition) {
  /**
   * A placeholder for an unknown edition value.
   *
   * @generated from enum value: EDITION_UNKNOWN = 0;
   */
  Edition[Edition["EDITION_UNKNOWN"] = 0] = "EDITION_UNKNOWN";
  /**
   * A placeholder edition for specifying default behaviors *before* a feature
   * was first introduced.  This is effectively an "infinite past".
   *
   * @generated from enum value: EDITION_LEGACY = 900;
   */
  Edition[Edition["EDITION_LEGACY"] = 900] = "EDITION_LEGACY";
  /**
   * Legacy syntax "editions".  These pre-date editions, but behave much like
   * distinct editions.  These can't be used to specify the edition of proto
   * files, but feature definitions must supply proto2/proto3 defaults for
   * backwards compatibility.
   *
   * @generated from enum value: EDITION_PROTO2 = 998;
   */
  Edition[Edition["EDITION_PROTO2"] = 998] = "EDITION_PROTO2";
  /**
   * @generated from enum value: EDITION_PROTO3 = 999;
   */
  Edition[Edition["EDITION_PROTO3"] = 999] = "EDITION_PROTO3";
  /**
   * Editions that have been released.  The specific values are arbitrary and
   * should not be depended on, but they will always be time-ordered for easy
   * comparison.
   *
   * @generated from enum value: EDITION_2023 = 1000;
   */
  Edition[Edition["EDITION_2023"] = 1000] = "EDITION_2023";
  /**
   * @generated from enum value: EDITION_2024 = 1001;
   */
  Edition[Edition["EDITION_2024"] = 1001] = "EDITION_2024";
  /**
   * Placeholder editions for testing feature resolution.  These should not be
   * used or relyed on outside of tests.
   *
   * @generated from enum value: EDITION_1_TEST_ONLY = 1;
   */
  Edition[Edition["EDITION_1_TEST_ONLY"] = 1] = "EDITION_1_TEST_ONLY";
  /**
   * @generated from enum value: EDITION_2_TEST_ONLY = 2;
   */
  Edition[Edition["EDITION_2_TEST_ONLY"] = 2] = "EDITION_2_TEST_ONLY";
  /**
   * @generated from enum value: EDITION_99997_TEST_ONLY = 99997;
   */
  Edition[Edition["EDITION_99997_TEST_ONLY"] = 99997] = "EDITION_99997_TEST_ONLY";
  /**
   * @generated from enum value: EDITION_99998_TEST_ONLY = 99998;
   */
  Edition[Edition["EDITION_99998_TEST_ONLY"] = 99998] = "EDITION_99998_TEST_ONLY";
  /**
   * @generated from enum value: EDITION_99999_TEST_ONLY = 99999;
   */
  Edition[Edition["EDITION_99999_TEST_ONLY"] = 99999] = "EDITION_99999_TEST_ONLY";
  /**
   * Placeholder for specifying unbounded edition support.  This should only
   * ever be used by plugins that can expect to never require any changes to
   * support a new edition.
   *
   * @generated from enum value: EDITION_MAX = 2147483647;
   */
  Edition[Edition["EDITION_MAX"] = 2147483647] = "EDITION_MAX";
})(Edition || (Edition = {}));
/**
 * Describes the enum google.protobuf.Edition.
 */
export const EditionSchema = /*@__PURE__*/enumDesc(file_google_protobuf_descriptor, 0);